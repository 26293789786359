.btn {
  @apply border hover:border-gray-600 border-gray-400 w-full px-3 py-3 rounded-md shadow-md cursor-pointer transition duration-300;
}

.btn-lg {
  @apply px-3 py-3
}

.btn-sm {
  @apply px-2 py-2
}

button:disabled {
  @apply cursor-not-allowed !bg-gray-300;
}