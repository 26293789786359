@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background: #f4f7fa;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --toastify-toast-width: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, button {
  @apply transition duration-300;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

.select-menu-btn > div:nth-child(1) {
  @apply py-3;
}

.user_management_table_action {
  position: relative;
  font-weight: initial !important;
}

.user_management_table_action .mentor100-dropdown {
  position: initial !important;
}