/* @layer components {
  [type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], input, textarea, select {
    @apply focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none block w-full py-3 bg-white text-sm px-3 border border-gray-300 rounded-md;
  }
} */
.form-item [type="text"],
.form-item [type="email"],
.form-item [type="url"],
.form-item [type="password"],
.form-item [type="number"],
.form-item [type="date"],
.form-item [type="datetime-local"],
.form-item [type="month"],
.form-item [type="search"],
.form-item [type="tel"],
.form-item [type="time"],
.form-item [type="week"],
.form-item [multiple],
.form-item input:not([type="checkbox"]),
.form-item input:not([type="radio"]),
.form-item textarea,
.form-item select {
  @apply focus:ring-secondary focus:border-secondary focus:outline-none block w-full py-3 bg-white text-sm px-3 border border-gray-300 rounded-md;
}

.form-item select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  appearance: none;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}
.form-item input:disabled, .form-item select:disabled, .form-item textarea:disabled {
  @apply bg-gray-300 cursor-not-allowed;
}
.form-item [type="file"]::file-selector-button {
  @apply bg-gray-300 outline-none border-none p-3;
}